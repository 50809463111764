import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";



////modulos sistema
import sistema from "./vuex/modulos/sistema";
import configFuncionario from "./vuex/modulos/configFuncionarios";
import permissoes from "./vuex/modulos/permissoes"
import configEmpresa from "./vuex/modulos/configEmpresa"
import configUsuarios from "./vuex/modulos/configUsuarios"
import contrato from "./vuex/modulos/contrato"
import documentos from "./vuex/modulos/documentos"
import certificado from "./vuex/modulos/certificado"
import produto from "./vuex/modulos/produto"
import turma from "./vuex/modulos/turma"

import financeiro from "./vuex/modulos/financeiro/financeiro"
import protocolo from "./vuex/modulos/protocolo/protocolo"
import tipoProtocolo from "./vuex/modulos/protocolo/tipoProtocolo"
import servico from "./vuex/modulos/servico" 
import setor  from "./vuex/modulos/config/setor";
import contratoServico from "./vuex/modulos/financeiro/contratoServico";
import tf from "./vuex/modulos/protocolo/tf";
import arqtf from "./vuex/modulos/protocolo/arqtf";
import atleta from "./vuex/modulos/atleta";
import offline from "./vuex/modulos/offline";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        sistema,
        configFuncionario,
        permissoes,
        configEmpresa,
        configUsuarios,
        protocolo,
        contrato,
        documentos,  
        financeiro,
        produto,
        turma,
        offline,
        certificado,
        tipoProtocolo,
        servico,
        setor,
        contratoServico,
        tf,
        arqtf,
        atleta
   


    }
});