import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_ATLETAS = "setListAtletas"
export const SET_LISTA_ATLETA_PESQUISA = "setListAtletaPes"
export const SET_LISTA_ATLETA = "setListAtleta"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_LISTA_ATLETA_MENSALIDADE = 'setListAtletaPlano'


const actions = {
    async create_atleta(context, value) {
        let message = await ApiService.post('atleta', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_atleta(context, value) {
        let message = await ApiService.put('atleta/'+value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async listar_atletas(context) {
        await ApiService.get('atleta')
            .then(response => context.commit(SET_LISTA_ATLETAS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async listar_atleta(context, id) {
        await ApiService.get('atleta/' + id)
            .then(response =>
                context.commit(SET_LISTA_ATLETA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async listar_atleta_mensalidade(context, id) {
        
        await ApiService.post('fatura/mensalidade/' + id)
            .then(response =>{
        context.commit(SET_MESSAGE_ALERT, {tipo: 'warning', message: response.data})

                context.commit(SET_LISTA_ATLETA_MENSALIDADE, response.data.conteudo)
            
            
            }
                
                )
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    
    
    async comprar_ingresso(context, value) {
        let message = await ApiService.post('fatura/pagamento', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async habilitarMensalidade(context, value) {
        let message = await ApiService.put('atleta/habilitarMensalidade/'+ value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    
    async delete_atleta(context, value) {
        let message = await ApiService.delete('atleta/' + value.id)
          .then(response => ({ tipo: 'success', message: response.data }))
          .catch((error) => ({ tipo: 'error', message: error.response.data }));
        context.commit(SET_MESSAGE_ALERT, message);
    
        state.lista_atleta = state.lista_atleta.filter((array) => array.id !== value.id);
        context.commit(SET_LISTA_ATLETA, state.lista_atleta);
      },



    
  
    // async pesquisar_atletas(context, texto, tipo) {
    //     await ApiService.get('atleta/', texto, '/', tipo)
    //         .then(response => context.commit(SET_LISTA_ATLETA_PESQUISA, response.data))
    //         .catch((error) => context.commit(SET_MESSAGE_ALERT, {
    //             tipo: 'error', message: error.response.data
    //         }))
    // },
    


    
};

const getters = {};

const mutations = {
    [SET_LISTA_ATLETAS](state, value) {
        state.lista_atletas = value
    },
    [SET_LISTA_ATLETA_PESQUISA](state, value) {
        state.lista_atletas = value
    },
    [SET_LISTA_ATLETA](state, value) {
        state.lista_atleta = value
    },
[SET_LISTA_ATLETA_MENSALIDADE](state,value){
    state.lista_mensalidade = value
},
 
    
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
          
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },
};

const state = {
    lista_atletas: [],
    lista_atleta: [],
    lista_mensalidade: [],

    mensagem_alert: "",

};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};