import ApiService from "@/core/services/api.service";

export const SET_LISTA_USUARIOS = "setListUsuariosOff"
export const SET_LISTA_PERFIL = "setListPerfilOf"
export const SET_MESSAGE_ALERT = 'setMensagem'

export const SET_CEP = 'setCep'
const actions = {
    async create_usuario(context, value) {
        let message = await ApiService.post('hundred/usuario', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
  
    async listar_perfil(context) {
        await ApiService.get("hundred/perfil")
          .then((response) => context.commit(SET_LISTA_PERFIL, response.data))
          .catch((error) =>
            context.commit("SET_MESSAGE_ALERT", {
              tipo: "error",
              message: error.response.data,
            })
          );
      },
  
    async set_cep(context, value) {
        await ApiService.get('hundred/cep/' + value)
            .then(response =>
                context.commit(SET_CEP, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
   
    
};

const getters = {};

const mutations = {
    [SET_LISTA_USUARIOS](state, value) {
        state.lista_usuarios = value
    },
   
    [SET_LISTA_PERFIL](state, value) {
        state.lista_perfil = value
    },
    [SET_CEP](state, value) {
        state.cep = value
    },
    
    [SET_MESSAGE_ALERT](state, value) {
        let customMessage;
        if (typeof value.message === "object") {
          
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
};

const state = {
    lista_usuarios: [],
    lista_perfil: [],
    mensagem_alert: "",
    cep:{}

};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};