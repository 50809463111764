import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_TURMAS = "setListTurmas";
export const SET_MESSAGE_ALERT = "setMensagem";
export const SET_TURMA = 'setTurma'

const actions = {
  async create_turma(context, value) {
    let message = await ApiService.post('turma', value)
      .then(response => ({ tipo: 'success', message: response.data }))
      .catch((error) => ({ tipo: 'error', message: error.response.data }));
    context.commit(SET_MESSAGE_ALERT, message);
  },
  async update_turma(context, value) {
    let data = await ApiService.put('turma/' + value.id, value)
      .then(response => ({ tipo: 'success', message: response.data }))
      .catch((error) => ({ tipo: 'error', message: error.response.data }));
    context.commit(SET_MESSAGE_ALERT, data);
    context.commit(SET_TURMA, data.message.conteudo);
  },
  async listar_turmas(context, params) {
    await ApiService.get('turma')
      .then(response => context.commit(SET_LISTA_TURMAS, response.data))
      .catch((error) => context.commit(SET_MESSAGE_ALERT, { tipo: 'error', message: error.response.data }));
  },
  async bloquear_turma(context, value) {
    let message = await ApiService.delete('turma/' + value.id)
      .then(response => ({ tipo: 'success', message: response.data }))
      .catch((error) => ({ tipo: 'error', message: error.response.data }));
    context.commit(SET_MESSAGE_ALERT, message);

    state.lista_turmas = state.lista_turmas.filter((array) => array.id !== value.id);
    context.commit(SET_LISTA_TURMAS, state.lista_turmas);
  },

  // Add other actions related to TURMA if needed.
};

const mutations = {
  [SET_LISTA_TURMAS](state, value) {
    state.lista_turmas = value;
  },
  [SET_TURMA](state, value) {
    state.turma = value;
  },
  
  [SET_MESSAGE_ALERT](state, value) {
    console.log(value);
    let customMessage;
    if (typeof value.message === "object") {
      customMessage = value.message.msg;
    }
    state.mensagem_alert = {
      tipo: value.tipo,
      message: customMessage ? customMessage : value.message,
    };
  },
  // Add other mutations related to TURMA if needed.
  // ...
};

const state = {
  lista_turmas: [],
  turma: {},

  mensagem_alert: "",
  // Add other state properties related to TURMA if needed.
  // ...
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
